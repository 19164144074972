import { useAmplifyAuth } from '@loggi/authentication-lib';
import { Box } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useSnackbar } from 'hooks';
import useQueryParams from 'hooks/query-params/query-params.hook';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { companyIdGuard } from 'routes/routes';
import checkIsMobile from 'utils/check-is-mobile/check-is-mobile.helper';
import SignUpUserUI from 'UI/pages/sign-up/user/sign-up-user.component';
import CRMUser from 'crm/entities/user/crm-user.model';

const SignUpUser = () => {
  const showSnackbar = useSnackbar();
  const { t } = useTranslation('containers');
  const isMobile = checkIsMobile();
  const history = useHistory();
  const { redirect } = useQueryParams();
  const [hasSignedUp, setHasSignedUp] = useState(false);

  const {
    signUp,
    state: { error: stateError, authenticatedUser }
  } = useAmplifyAuth();

  const handleOnGoBackClick = useCallback(() => {
    return history.goBack();
  }, [history]);

  useEffect(() => {
    if (authenticatedUser) {
      const queryParam = redirect ? `?redirect=${redirect}` : '';
      history.push(`${companyIdGuard}${queryParam}`);
    }
  }, [authenticatedUser, history, redirect]);

  useEffect(() => {
    if (stateError) {
      const translationKey = `signUp.errorMessages.${stateError.message?.code}`;
      let errorMessage = t(translationKey);
      const translationFound = errorMessage !== translationKey;
      if (!translationFound) {
        Sentry.captureException(stateError);
        errorMessage = t(`signUp.errorMessages.genericError`, {
          detail: stateError.message?.message
        });
      }
      showSnackbar({ message: errorMessage, severity: 'error' });
    }
  }, [stateError, showSnackbar, t]);

  const handleSignUpUser = async ({ email, name, password }) => {
    const result = await signUp(email.toLowerCase(), name, password);
    setHasSignedUp(!!result);

    const currentSearchParams = new URLSearchParams(window.location.search);
    const nextPage = currentSearchParams.get('next_page');

    if (nextPage) {
      localStorage.setItem('next_page', nextPage);
    }

    if (result) {
      const crmUser = new CRMUser({ email, firstName: name });
      crmUser.sendToCrm();
    }
  };

  return (
    <Box
      style={{ height: '100vh' }}
      display={isMobile ? 'auto' : 'flex'}
      alignItems="center"
    >
      <SignUpUserUI
        onGoBack={handleOnGoBackClick}
        onSubmit={handleSignUpUser}
        hasSignedUp={hasSignedUp}
      />
    </Box>
  );
};

export default SignUpUser;
