import CRMBaseEvent from 'crm/entities/events/crm-base-event';
import EVENTS from 'crm/constants';
import {
  getFirstName,
  getLastName,
  getMonthlyPackagesRangeRoasPrice
} from 'crm/utils';

export default class AccountCreationCompletedRoas extends CRMBaseEvent {
  constructor({
    companyId,
    name,
    email,
    origin,
    phone,
    cnpj,
    cpf,
    price,
    multiplicador
  }) {
    super();
    this.eventName = EVENTS.ACCOUNT_CREATION_COMPLETED_ROAS;
    this.payload = {
      company_id: companyId ? String(companyId) : undefined,
      first_name: getFirstName(name),
      last_name: getLastName(name),
      email,
      origin,
      phone,
      document: cnpj || cpf,
      price: getMonthlyPackagesRangeRoasPrice(price) * multiplicador
    };
  }
}
