import { Box, Fade, Slide } from '@mui/material';
import { ReactComponent as PersonWithCoffeeIllustration } from 'assets/create-user-success.svg';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFeatureSwitch } from 'hooks/feature-switch';
import { Header, InfiniteLoading, SuccessPage } from 'UI/components';
import { useCurrentUser } from 'auth';
import getStyle from 'UI/pages/utils/utils';
import checkIsMobile from 'utils/check-is-mobile/check-is-mobile.helper';
import CompanyDataForm from './form/user-data/user-data-form.component';
import CpfStepForm from './form/natural-person/cpf/cpf-step-form.component';
import CnpjStepForm from './form/legal-person/cnpj/cnpj-step-form.component';
import SignUpModalityForm from './form/signup-modality/signup-modality-form.component';
import CepStepForm from './form/legal-person/cep/cep-step-form.component';
import SegmentStepForm from './form/legal-person/segment/segment-step-form.component';
import EstimatedMonthlyPackagesStepForm from './form/legal-person/estimated-monthly-packages/estimated-monthly-packages-step-form.component';
import NewEstimatedMonthlyPackagesStepForm from './form/new-estimated-monthly-packages/new-estimated-monthly-packages-step-form.component';
import EstimatedMonthlyPackagesNaturalPerson from './form/natural-person/estimated-monthly-packages/estimated-monthly-packages-step-form.component';
import AverageTicketStepForm from './form/legal-person/average-ticket/average-ticket-step-form.component';
import IntegratorStepForm from './form/legal-person/integrator/integrator-step-form.component';
import TermsAndConditionsStepForm from './form/legal-person/terms-and-conditions/terms-and-conditions-step-form.component';
import UsuallySendStepForm from './form/natural-person/usually-send/usually-send-step-form.component';

import {
  ORDENATED_STEPS_LIST,
  STEP_INITIAL,
  STEP_MODALITY,
  STEP_CPF,
  STEP_CNPJ,
  STEP_CEP,
  STEP_SEGMENT,
  STEP_PACKAGES,
  STEP_PACKAGES_PER_WEEK,
  STEP_TICKET,
  STEP_INTEGRATOR,
  STEP_TERMS_AND_CONDITIONS,
  STEP_USUALLY_SEND
} from './sign-up-company.constants';

export const COMPANY_SIGNUP_FORM_ID = 'company-signup-form';
export const COMPANY_SIGNUP_LOADING_ID = 'company-signup-loading';
export const COMPANY_SIGNUP_SUCCESS_ID = 'company-signup-success';

const SLIDE_TRANSITION_TIMEOUT = 640;
const FADE_TRANSITION_TIMEOUT = 800;

const SignUpCompany = ({ onGoBack, onSubmit, initialStep }) => {
  const { t } = useTranslation('ui');
  const isDesktop = !checkIsMobile();
  const user = useCurrentUser();
  const [isCompanyDataFormSubmitted, setIsCompanyDataFormSubmitted] = useState(
    false
  );
  const [isNaturalPerson, setIsNaturalPerson] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(initialStep);
  const enabledMetrificationSteps = useFeatureSwitch(
    'enabled_metrification_steps_fom',
    []
  );
  const { perMonthly, usuallySend } = enabledMetrificationSteps?.data;
  const { data: isEnableNewMonthlyPackagesRangePage } = useFeatureSwitch(
    'enable_new_monthly_packages_range_page',
    false
  );

  const { data: disableCepStepForm } = useFeatureSwitch(
    'disable_cep_step_form',
    false
  );

  const { data: disableSegmentStepForm } = useFeatureSwitch(
    'disable_segment_step_form',
    false
  );

  const { data: disableTicketStepForm } = useFeatureSwitch(
    'disable_ticket_step_form',
    false
  );

  const isEnabledPerMonthly = perMonthly || isEnableNewMonthlyPackagesRangePage;

  const handleSubmit = async userCredentials => {
    setIsCompanyDataFormSubmitted(true);
    setLoading(true);
    try {
      await onSubmit(userCredentials);
    } catch {
      setIsCompanyDataFormSubmitted(false);
    }
    setLoading(false);
  };

  const handleOnNextStep = ({ isLegalPerson } = {}) => {
    const isNaturalPersonFlow = currentStep === STEP_CPF || isNaturalPerson;

    if (isLegalPerson && currentStep === STEP_MODALITY) {
      setIsNaturalPerson(false);
      return setCurrentStep(STEP_CNPJ);
    }

    if (isNaturalPersonFlow) {
      setIsNaturalPerson(true);
      const goPackagesNaturalPersonStep =
        (isEnabledPerMonthly && currentStep === STEP_USUALLY_SEND) ||
        (isEnabledPerMonthly && currentStep === STEP_CPF);

      if (usuallySend && currentStep === STEP_CPF) {
        return setCurrentStep(STEP_USUALLY_SEND);
      }

      if (goPackagesNaturalPersonStep) {
        return setCurrentStep(STEP_PACKAGES_PER_WEEK);
      }

      return setCurrentStep(STEP_TERMS_AND_CONDITIONS);
    }

    if (currentStep === STEP_CNPJ) {
      if (disableCepStepForm && !disableSegmentStepForm) {
        return setCurrentStep(STEP_SEGMENT);
      }

      if (disableCepStepForm && disableSegmentStepForm) {
        return setCurrentStep(STEP_PACKAGES);
      }
    }

    if (currentStep === STEP_CEP && disableSegmentStepForm) {
      return setCurrentStep(STEP_PACKAGES);
    }

    if (currentStep === STEP_PACKAGES && disableTicketStepForm) {
      return setCurrentStep(STEP_INTEGRATOR);
    }

    const currentIndex = ORDENATED_STEPS_LIST.indexOf(currentStep);
    return setCurrentStep(ORDENATED_STEPS_LIST[currentIndex + 1]);
  };

  const handleOnGoBack = () => {
    const isUsuallySendStep =
      isNaturalPerson && currentStep === STEP_USUALLY_SEND;
    const hasOnlyPackagesNaturalPersonStep =
      isNaturalPerson && currentStep === STEP_PACKAGES_PER_WEEK && !usuallySend;
    const metrificationStepsIsDisabled = !isEnabledPerMonthly && !usuallySend;
    const hasOnlyTermsAndConditionsStep =
      isNaturalPerson &&
      currentStep === STEP_TERMS_AND_CONDITIONS &&
      metrificationStepsIsDisabled;
    const returnToCpfStep =
      isUsuallySendStep ||
      hasOnlyPackagesNaturalPersonStep ||
      hasOnlyTermsAndConditionsStep;

    if (currentStep === STEP_INITIAL) return onGoBack();
    if (currentStep === STEP_CNPJ) return setCurrentStep(STEP_MODALITY);
    if (returnToCpfStep) return setCurrentStep(STEP_CPF);
    if (isNaturalPerson && currentStep === STEP_PACKAGES_PER_WEEK)
      return setCurrentStep(STEP_USUALLY_SEND);
    if (
      isNaturalPerson &&
      currentStep === STEP_TERMS_AND_CONDITIONS &&
      isEnabledPerMonthly
    )
      return setCurrentStep(STEP_PACKAGES_PER_WEEK);
    if (
      isNaturalPerson &&
      currentStep === STEP_TERMS_AND_CONDITIONS &&
      usuallySend
    )
      return setCurrentStep(STEP_USUALLY_SEND);

    if (currentStep === STEP_SEGMENT && disableCepStepForm) {
      return setCurrentStep(STEP_CNPJ);
    }

    if (currentStep === STEP_PACKAGES) {
      if (disableSegmentStepForm && !disableCepStepForm) {
        return setCurrentStep(STEP_CEP);
      }
      if (disableSegmentStepForm && disableCepStepForm) {
        return setCurrentStep(STEP_CNPJ);
      }
    }

    if (currentStep === STEP_INTEGRATOR && disableTicketStepForm) {
      return setCurrentStep(STEP_PACKAGES);
    }

    const currentIndex = ORDENATED_STEPS_LIST.indexOf(currentStep);
    return setCurrentStep(ORDENATED_STEPS_LIST[currentIndex - 1]);
  };

  const stepsComponentsTable = {
    [STEP_INITIAL]: (
      <CompanyDataForm
        handleOnNextStep={handleOnNextStep}
        onSubmit={handleSubmit}
        userEmail={user?.email}
        userName={user?.name}
      />
    ),
    [STEP_MODALITY]: <SignUpModalityForm handleOnNextStep={handleOnNextStep} />,
    [STEP_CPF]: <CpfStepForm onSubmit={handleOnNextStep} />,
    [STEP_CNPJ]: <CnpjStepForm handleOnNextStep={handleOnNextStep} />,
    [STEP_CEP]: <CepStepForm handleOnNextStep={handleOnNextStep} />,
    [STEP_SEGMENT]: <SegmentStepForm handleOnNextStep={handleOnNextStep} />,
    [STEP_PACKAGES]: isEnableNewMonthlyPackagesRangePage ? (
      <NewEstimatedMonthlyPackagesStepForm
        handleOnNextStep={handleOnNextStep}
      />
    ) : (
      <EstimatedMonthlyPackagesStepForm handleOnNextStep={handleOnNextStep} />
    ),
    [STEP_TICKET]: (
      <AverageTicketStepForm handleOnNextStep={handleOnNextStep} />
    ),
    [STEP_INTEGRATOR]: (
      <IntegratorStepForm handleOnNextStep={handleOnNextStep} />
    ),
    [STEP_TERMS_AND_CONDITIONS]: (
      <TermsAndConditionsStepForm onSubmit={handleSubmit} user={user} />
    ),
    [STEP_USUALLY_SEND]: (
      <UsuallySendStepForm handleOnNextStep={handleOnNextStep} />
    ),
    [STEP_PACKAGES_PER_WEEK]: isEnableNewMonthlyPackagesRangePage ? (
      <NewEstimatedMonthlyPackagesStepForm
        handleOnNextStep={handleOnNextStep}
      />
    ) : (
      <EstimatedMonthlyPackagesNaturalPerson
        handleOnNextStep={handleOnNextStep}
      />
    )
  };

  const getSignUpStepContent = () => {
    return stepsComponentsTable[currentStep] || <></>;
  };

  return (
    <Box style={{ ...getStyle(isDesktop), overflowX: 'hidden' }}>
      <Slide
        direction="left"
        in={!isCompanyDataFormSubmitted}
        timeout={SLIDE_TRANSITION_TIMEOUT}
        unmountOnExit
      >
        <Box height="100vh">
          <Header
            onGoBack={handleOnGoBack}
            goBackLabel={
              currentStep !== STEP_INITIAL
                ? t('header.goBackButtonLabel')
                : t('header.logout')
            }
            data-testid={COMPANY_SIGNUP_FORM_ID}
          />
          {getSignUpStepContent()}
        </Box>
      </Slide>
      <Slide
        direction="left"
        in={loading}
        mountOnEnter
        timeout={SLIDE_TRANSITION_TIMEOUT}
        unmountOnExit
      >
        <Box
          data-testid={COMPANY_SIGNUP_LOADING_ID}
          display="flex"
          justifyContent="center"
          height="100vh"
          px={3}
          py={6}
        >
          <InfiniteLoading
            title={t('signUpCompany.loadingScreen.title')}
            description={t('signUpCompany.loadingScreen.description')}
          />
        </Box>
      </Slide>
      <Fade
        in={isCompanyDataFormSubmitted && !loading}
        mountOnEnter
        timeout={FADE_TRANSITION_TIMEOUT}
        unmountOnExit
      >
        <Box data-testid={COMPANY_SIGNUP_SUCCESS_ID} px={3} py={6}>
          <SuccessPage
            goToHomeButtonLabel={t(
              'signUpCompany.successScreen.finishButtonLabel'
            )}
            illustration={<PersonWithCoffeeIllustration />}
            subtitles={[t('signUpCompany.successScreen.description')]}
            title={t('signUpCompany.successScreen.title')}
          />
        </Box>
      </Fade>
    </Box>
  );
};

SignUpCompany.defaultProps = {
  initialStep: STEP_INITIAL
};

SignUpCompany.propTypes = {
  initialStep: PropTypes.string,
  onGoBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default SignUpCompany;
