import { loggiWebApi } from '@loggi/authentication-lib';
import { HTTP_HEADERS } from 'constants/headers.constants';
import { getFsValueUseCase } from 'domain/use-cases/get-fs-value/get-fs-value.use-case';

const ACCOUNTS_COMPANIES_ENDPOINT = '/one/api/accounts/v1/companies/';

const shouldIncludeCustomHeaders = async () => {
  try {
    const currentEnvironment = process.env.REACT_APP_ENV_SENTRY;
    const environments = await getFsValueUseCase(
      'allowed_environment_send_custom_headers'
    );
    if (
      environments &&
      typeof environments === 'object' &&
      environments.length > 0
    ) {
      return environments.indexOf(currentEnvironment) !== -1;
    }

    return false;
  } catch (_err) {
    return false;
  }
};

const formatCompanyPayload = (companyData, utms) => {
  const {
    email,
    phone,
    cpf,
    cep,
    cnpj,
    averageTicket,
    sharedName,
    noL4BStatus,
    integrators,
    otherIntegrator,
    segment,
    estimatedMonthlyPackages
  } = companyData;
  let payload = {};
  if (cpf) {
    payload = {
      cpf,
      landline_1: phone,
      company_configuration_json: {
        estimated_monthly_packages: estimatedMonthlyPackages
      }
    };
  } else if (cnpj) {
    payload = {
      cnpj,
      landline_1: phone,
      landline_2: phone,
      shared_name: sharedName,
      company_configuration_json: {
        segment,
        average_ticket: averageTicket ? Number(averageTicket) : null,
        estimated_monthly_packages: estimatedMonthlyPackages,
        postal_code: cep,
        owner_email: email,
        is_no_l4b_status: noL4BStatus
      }
    };
    if (integrators.length || otherIntegrator) {
      payload.company_configuration_json.integrators = otherIntegrator
        ? [...integrators, otherIntegrator]
        : integrators;
    }
  }
  const companyConfigWithoutUTMs = payload.company_configuration_json;
  payload.company_configuration_json = companyConfigWithoutUTMs
    ? {
        ...utms,
        ...companyConfigWithoutUTMs
      }
    : utms ?? undefined;

  return payload;
};

const companiesAccountApi = {
  signupCompany: (payload, utms) => {
    const formattedPayload = formatCompanyPayload(payload, utms);

    return loggiWebApi
      .url(ACCOUNTS_COMPANIES_ENDPOINT)
      .post(formattedPayload)
      .json();
  },
  getCompany: async () => {
    const includeCustomHeaders = await shouldIncludeCustomHeaders();

    if (includeCustomHeaders)
      return loggiWebApi
        .headers(HTTP_HEADERS)
        .url(ACCOUNTS_COMPANIES_ENDPOINT)
        .get()
        .json();

    return loggiWebApi
      .url(ACCOUNTS_COMPANIES_ENDPOINT)
      .get()
      .json();
  },
  validateCompanyData: (sharedName, cnpj) =>
    loggiWebApi
      .url(`${ACCOUNTS_COMPANIES_ENDPOINT}validate/`)
      .post({ shared_name: sharedName, cnpj })
      .json(),
  createCustomAccessIfHasCompany: () => {
    return loggiWebApi
      .url(`${ACCOUNTS_COMPANIES_ENDPOINT}access/`)
      .post()
      .json();
  }
};

export default companiesAccountApi;
