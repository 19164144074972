import { Button, Header } from 'UI/components';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import InitialPageBackgroud from 'assets/initial-page-backgroud.svg';
import { login as loginRoute, tracker as trackerRoute } from 'routes/routes';
import { root } from 'theme/colors';
import { useDomainUseCase } from 'UI/contexts';
import PreSignVisited from 'crm/entities/events/presign-visited/presign-visited';
import { useFeatureSwitch } from 'hooks/feature-switch';
import { withEventsObserver } from 'UI/decorators/events-observer/events-observer';
import { USER_INTENTION } from 'crm/constants';
import UserIntentionInformed from 'crm/entities/events/user-intention-informed/user-intention-informed';
import { getFsValueUseCase } from 'domain/use-cases/get-fs-value/get-fs-value.use-case';
import { BannerDownloadApp } from 'UI/acquisition/components';
import checkIsMobile from 'utils/check-is-mobile/check-is-mobile.helper';
import { hasWebViewInterface } from 'mobile';

export const useInitialPageEnable = () => {
  const [isInitialPageEnabled, setIsInitialPageEnabled] = useState(null);
  const isInitialPageEnabledUseCase = useDomainUseCase(
    'isInitialPageEnabledUseCase'
  );

  useEffect(() => {
    if (!isInitialPageEnabled) {
      isInitialPageEnabledUseCase().then(setIsInitialPageEnabled);
    }
  }, [isInitialPageEnabledUseCase, isInitialPageEnabled]);
  return isInitialPageEnabled;
};

const InitialPage = () => {
  const { t } = useTranslation('ui');
  const history = useHistory();
  const { data: isPresignVisitedEventEnabled, isFetched } = useFeatureSwitch(
    'enable_send_presign_visited_event'
  );

  const { data: isInternalTrackerPageEnabled } = useFeatureSwitch(
    'internal_tracker_page_enabled',
    false
  );

  const redirectToTracking = () => {
    if (isInternalTrackerPageEnabled) {
      history.push(trackerRoute);
      return;
    }

    const externalTrackerPage = `${process.env.REACT_APP_SHARE_TRACKER_URL}`;
    window.location.replace(externalTrackerPage);
  };

  const redirectToLogin = () => {
    history.push(loginRoute);
  };

  useEffect(() => {
    if (isPresignVisitedEventEnabled && isFetched) {
      new PreSignVisited().sendToCrm();
    }
  }, [isPresignVisitedEventEnabled, isFetched]);

  const isMobileBrowser = checkIsMobile() && !hasWebViewInterface();

  return (
    <Box
      style={{
        height: '100vh',
        width: '100%'
      }}
    >
      <BannerDownloadApp />
      <Box
        style={{
          position: 'relative',
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7)), url(${InitialPageBackgroud})`,
          minHeight: isMobileBrowser ? 'calc(80% - 50px)' : '80%',
          minWidth: '100%',
          backgroundPosition: 'center',
          backgroundSize: '100% 100%'
        }}
      >
        <Box>
          <Header variant="transparent" />
        </Box>
        <Box
          color={root[0]}
          ml={2}
          style={{
            position: 'absolute',
            top: '85%',
            left: '32%',
            transform: 'translate(-50%, -50%)'
          }}
        >
          <Typography variant="h2" fontWeight="fontWeightMedium">
            {t('initialPage.title')}
          </Typography>
          <Typography variant="h2" mb={2}>
            <strong>{t('initialPage.titleContinue')}</strong>
          </Typography>
          <Typography variant="subtitle2" fontWeight="fontWeightMedium">
            {t('initialPage.subtitle')}
          </Typography>
        </Box>
      </Box>

      <Box p={2} mt={1}>
        <Box>
          <Button
            id="continue_shipment_button"
            data-testid="continue-shipment-button"
            fullWidth
            height={48}
            label={t('initialPage.continueShipmentButton')}
            onClick={redirectToLogin}
          />
        </Box>
        <Box mt={2}>
          <Button
            id="continue_tracking_button"
            data-testid="continue-tracking-button"
            fullWidth
            height={48}
            label={t('initialPage.continueTrackingButton')}
            textColor={root[900]}
            bgColor={root[0]}
            onClick={redirectToTracking}
          />
        </Box>
      </Box>
    </Box>
  );
};
export default InitialPage;

export const InitialPageWithEventsObserver = withEventsObserver(InitialPage, {
  continue_shipment_button: {
    click: () => {
      getFsValueUseCase('enable_user_intention_informed_event').then(
        isEnabled => {
          if (isEnabled) {
            new UserIntentionInformed({
              intention: USER_INTENTION.CREATE_SHIPMENT
            }).sendToCrm();
          }
        }
      );
    }
  },
  continue_tracking_button: {
    click: () => {
      getFsValueUseCase('enable_user_intention_informed_event').then(
        isEnabled => {
          if (isEnabled) {
            new UserIntentionInformed({
              intention: USER_INTENTION.TRACKING
            }).sendToCrm();
          }
        }
      );
    }
  }
});
